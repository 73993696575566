<template>
  <v-navigation-drawer
    v-model="Sidebar_drawer"
    :dark="SidebarColor !== 'white'"
    :color="SidebarColor"
    mobile-breakpoint="960"
    clipped
    :right="$vuetify.rtl"
    mini-variant-width="70"
    :expand-on-hover="expandOnHover"
    app
    id="main-sidebar"
    v-bar
  >
    <!---USer Area -->
    <v-list-item two-line class="profile-bg">
      <v-list-item-avatar color="white pa-0 my-12" min-width="50" height="50" width="50">
        <v-icon color="#3896E8">fas fa-user</v-icon>
        <!-- <img src="https://randomuser.me/api/portraits/men/81.jpg" /> -->
      </v-list-item-avatar>

      <v-list-item-content class="white--text">
        <v-list-item-title>{{ usuarioname }}</v-list-item-title>
        <v-list-item-subtitle class="caption white--text">Bienvenido</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <!---USer Area -->
    <v-list expand nav class="mt-1">
      <v-divider></v-divider>
      <template v-for="(item, i) in items">
        <!---If Sidebar Caption -->
        <v-row v-if="item.header" :key="item.header" align="center">
          <v-col v-if="verifyAuthAcl(item.meta.code)" cols="12">
            <v-subheader v-if="item.header" class="d-block text-truncate">{{ item.header }}</v-subheader>
          </v-col>
        </v-row>
        <!---If Sidebar Caption -->
        <div v-else-if="item.children" :key="`div-group-${i}`">
          <BaseItemGroup v-if="verifyAuthAcl(item.meta.code)" :key="`group-${i}`" :item="item"></BaseItemGroup>
        </div>

        <div v-else :key="`div-item-${i}`">
          <BaseItem v-if="verifyAuthAcl(item.meta.code)" :key="`item-${i}`" :item="item" />
        </div>
      </template>
      <!---Sidebar Items -->
    </v-list>
    <!-- <v-divider></v-divider> -->
    <!--- Progress -->
    <!-- <v-list-item two-line>
      <v-list-item-content class>
        <v-list-item-title class="d-flex mb-3 align-center">
          <span class="body-2 text-truncate">monthly profit</span>
          <div class="ml-auto">
            <h6 class="mb-0 info--text">80%</h6>
          </div>
        </v-list-item-title>
        <v-progress-linear rounded value="80"></v-progress-linear>
      </v-list-item-content>
    </v-list-item>
    <v-list-item two-line>
      <v-list-item-content class>
        <v-list-item-title class="d-flex mb-3 align-center">
          <span class="body-2 text-truncate">Sales of the year</span>
          <div class="ml-auto">
            <h6 class="mb-0 success--text">54%</h6>
          </div>
        </v-list-item-title>
        <v-progress-linear color="success" rounded value="54"></v-progress-linear>
      </v-list-item-content>
    </v-list-item> -->
    <!--- Progress -->
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';
import SidebarItems from './SidebarItems';
import { decryptAES } from '@/components4x/utils/utils4x';
export default {
  name: 'Sidebar',
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    usuarioname: '',
    items: SidebarItems
  }),
  computed: {
    ...mapState(['SidebarColor', 'SidebarBg']),
    Sidebar_drawer: {
      get() {
        return this.$store.state.Sidebar_drawer;
      },
      set(val) {
        this.$store.commit('SET_SIDEBAR_DRAWER', val);
      }
    },
    ListPermissions() {
      return [...this.$store.getters.getFullPermissions];
    }
  },
  watch: {
    '$vuetify.breakpoint.smAndDown'(val) {
      this.$emit('update:expandOnHover', !val);
    }
  },

  methods: {
    verifyAuthAcl(code) {
      if (code === '*') {
        return true;
      }
      const index = this.ListPermissions.indexOf(code);
      return index !== -1;
    }
  },
  created() {
    this.usuarioname = decryptAES(localStorage.getItem('ntt04h4b8b'));
  }
};
</script>
<style lang="scss">
#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  .centro-bg {
    margin-top: -160px !important;
  }
  .v-navigation-drawer__border {
    display: none;
  }
  .v-list {
    padding: 8px 15px;
  }
  .v-list-item {
    &__icon--text,
    &__icon:first-child {
      justify-content: center;
      text-align: center;
      width: 20px;
    }
  }
  .profile-bg {
    background: url('../../../assets/images/user-info.jpg') no-repeat;
    .v-avatar {
      padding: 45px 0;
    }
  }
}
</style>
